<script>
import { socket } from "@/socket";

export default {
  name: "likes",
  props: {
    likes: Array,
    totalLikes: Number,
    postId: String,
    nameEvent: String,
    isLike: String,
    modalaction: Function,
    showBlack: Boolean,
  },
  data() {
    return {};
  },
  computed: {
    socketIsReady() {
      return !!this.$store.getters["connectedUsers/getSocketState"];
    },
    getTotal() {
      return this.totalLikes;
    },
    isMyLike() {
      if (this?.likes) {
        return this.likes.some((it) => it.userId._id === this.$store.state.user.user._id);
      } else {
        return false;
      }
    },
  },
  watch: {
    isLike(newValue) {
      if (newValue === this.postId) this.onLike();
    },
  },
  methods: {
    onLike(event) {
      event.stopPropagation();
      if (navigator.onLine && this.socketIsReady) {
        event.target.classList.toggle("liked");
        socket.emit(this.nameEvent, { id: this.postId });
      }
    },
  },
};
</script>
<template>
  <div class="like">
    <div class="like__button">
      <div class="heart-bg">
        <div :class="['heart-icon', isMyLike ? 'liked' : '', showBlack ? 'heartBlack-icon' : 'heart-icon']" @click.prevent="onLike"></div>
      </div>
      <div :class="`like__icoText like__icoText--${showBlack ? 'black' : ''}`">
        <p class="likes__total" v-if="totalLikes" @click="modalaction">{{ getTotal }}</p>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.like {
  &__icoText {
    color: white;
    position: relative;
    min-width: 10px;
    text-align: center;
    padding: 0 5px;
    font-weight: 600;
    cursor: pointer;
    &--black {
      color: black;
    }
  }
  &__button {
    display: flex;
    align-items: center;

    .heart-icon {
      height: 70px;
      width: 70px;
      background: url("../../../assets/img/heart.png");
      background-position: left;
      cursor: pointer;
      position: absolute;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .heartBlack-icon {
      height: 70px;
      width: 70px;
      background: url("../../../assets/img/heart_black.png");
      background-position: left;
      cursor: pointer;
      position: absolute;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .heart-icon.liked {
      animation: like-anim 0.7s steps(28) forwards;
    }

    .heart-bg {
      background: rgba(255, 192, 200, 0);
      border-radius: 50%;
      height: 40px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 100ms ease;
    }

    .heart-bg:hover {
      transform: scale(1.3);
    }

    .likes-amount {
      font-size: 20px;
      font-family: "Roboto", sans-serif;
      color: #888;
      font-weight: 900;
      margin-left: 6px;
    }
  }

  @keyframes like-anim {
    to {
      background-position: right;
    }
  }
}
</style>
